import React from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link as LinkProps } from 'shared/interfaces/Link';

import './stylesheets/button.scss';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface ButtonProps {
  children?: JSX.Element | string;
  customClassName?: string;
  icon?: [IconPrefix, IconName] | IconName;
  extraButtonIcon?: [IconPrefix, IconName] | IconName;
  iconOnly?: boolean;
  iconPrefix?: IconPrefix;
  text?: string;
  variant?: string;
  link?: LinkProps | string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  id?: string;
  openInNewTab?: boolean;
  iconColor?: string
}

const getIconData = (
  icon: [IconPrefix, IconName] | IconName | undefined,
  iconPrefix: IconPrefix
): IconProp | undefined => {
  if (typeof icon === 'string') {
    const formattedIcon = icon.replace('_', '-') as IconName;
    return [iconPrefix, formattedIcon] as IconProp;
  } else if (Array.isArray(icon)) {
    return icon as IconProp;
  }
  return undefined;
};

const Button = (props: ButtonProps): React.ReactElement => {
  const { customClassName, icon, extraButtonIcon, iconOnly = false, iconColor, iconPrefix = 'fas', text, link, onClick, onMouseEnter, onMouseLeave, children, id, openInNewTab } = props;

  const iconData = getIconData(icon, iconPrefix);
  const buttonIconData = getIconData(extraButtonIcon, iconPrefix);

  // Function to conditionally render link attributes
  const renderLinkAttributes = () => {
    if (typeof link === 'string') {
      return {
        href: link,
        ...(openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {}) // Add these attributes conditionally
      };
    } else if (link && typeof link === 'object') {
      // Destructure ariaLabel and the rest of the link props
      const { ariaLabel, ...restLinkProps } = link;
      // Return an object with the correct aria-label attribute
      return {
        ...restLinkProps,
        ...(ariaLabel ? { 'aria-label': ariaLabel } : {}),
        ...(openInNewTab ? { target: '_blank', rel: 'noreferrer' } : {}) // Also add these attributes conditionally
      };
    }
    return {};
  };

  return (
    <a
      {...renderLinkAttributes()}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={(event) => {
        if (onClick) {
          event.preventDefault();
          onClick()
        }
      }}
      className={`button${iconOnly ? ' button--icon-only button--btn-icon' : ''}${icon ? ' button--btn-icon' : ''} ${customClassName}`}>
      {iconData && <FontAwesomeIcon icon={iconData} className={`fa--${iconColor}`} />}
      {!iconOnly && text}
      {buttonIconData && <FontAwesomeIcon icon={buttonIconData} />}
      {children}
    </a>
  );
};

const Tab = (props: ButtonProps): React.ReactElement => {
  return <Button {...props} />;
}

export {
  Button,
  Tab
}
