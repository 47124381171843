import { mapColor } from "shared/helpers/colorMapping"
import { rem } from 'shared/helpers/styleHelpers';

interface BGSpacingProps {
  bgColor?: string
  className?: string
  children: React.ReactNode
  noPadding?: boolean
  paddingTop?: number
  paddingBottom?: number
}

const Wrapper = ({ bgColor, className, children, noPadding, paddingTop, paddingBottom }: BGSpacingProps) => {

  const wrapperPaddingTop = paddingTop ? rem(paddingTop) : rem(0);
  const wrapperPaddingBottom = paddingBottom ? rem(paddingBottom) : rem(0);

  return (
    <div className={!bgColor ? 'component__margin' : ''}>
      {(bgColor || className) ?
        <div className={`${bgColor ? `${!noPadding && 'background--padding'} background--${mapColor(bgColor)}` : ''} ${className}`}
          style={{ paddingTop: wrapperPaddingTop, paddingBottom: wrapperPaddingBottom }}
        >
          {children}
        </div>
        : children
      }
    </div>
  )
}

export default Wrapper