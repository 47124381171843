import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';
import { Link } from 'shared/interfaces/Link';
import StrapiButton from 'components/Strapi/Button';
import { Button as ButtonProps } from 'shared/interfaces/Button';
import Text from 'components/Text';
import Wrapper from "components/Strapi/Wrapper";
import rvaLogo from 'shared/assets/images/RVA_RISING_TRANS_Logo.png';

import './stylesheets/copy-blocks.scss';

export type IntroProps = {
  children?: JSX.Element | string;
  variation?: string;
  ctaText?: string;
  title?: string;
  header?: string;
  h1?: string;
  desc: string;
  p?: string;
  variant?: string;
  link?: Link;
  cta?: ButtonProps;
  bgColor?: {
    selectedColor: string
  },
  buttons?: {
    label: string;
    link: string;
  },
}

type IntroTwoColumnListData = {
  className?: string;
  title: string;
  subHeader?: string;
  desc: string;
  cta?: ButtonProps;
  btnClassName?: string;
  icon?: IconName | string;
  border: boolean;
}

type IntroTwoColumnListProps = {
  bgColor?: {
    selectedColor: string
  };
  items: IntroTwoColumnListData[];
  singleColumnIntro?: IntroProps
}

type ResourceListProps = {
  title: string;
  description: string;
  listItems: {
    title: string;
    description: string;
    isAlternate: boolean;
    cta?: ButtonProps;
  }[]
  bgColor?: {
    selectedColor: string
  };
}

export const TextTwoColumnList = (props: IntroTwoColumnListProps): React.ReactElement => {
  const { singleColumnIntro, bgColor, items } = props;

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <section>
        <div className='container container--large'>
          <div className='copy-block copy-block--text-two-column-list'>
            <div className='copy-block copy-block--text-two-column-list__flex-container'>
              {singleColumnIntro && (
                <div className='copy-block copy-block--text-two-column-list__header'>
                  <SingleColumn
                    title={singleColumnIntro.title}
                    desc={singleColumnIntro.desc}
                  />
                </div>
              )}
              {items && items[0] && items.map((({ title, subHeader, desc, cta, border }, index) => {
                return (
                  <div className={
                    border === true
                      ? 'copy-block copy-block--text-two-column-list__board--border'
                      : 'copy-block copy-block--text-two-column-list__board'
                  }
                    key={index}>
                    <div className='copy-block copy-block--text-two-column-list__header'>
                      <h4>{title}</h4>
                      {subHeader &&
                        <>
                          <span>|</span>
                          <h5>{subHeader}</h5>
                        </>
                      }
                    </div>
                    <div className='copy-block copy-block--text-two-column-list__content'>
                      <Text text={desc} />
                      {cta && (
                        <StrapiButton
                          data={cta}
                          customClassName={`button--${cta.className}`}
                          icon={typeof cta.icon === 'string' ? cta.icon as IconName : (cta.icon as unknown as [IconPrefix, IconName])}
                        />
                      )}
                    </div>
                  </div>
                )
              }))
              }
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export const IntroH1 = (props: IntroProps): React.ReactElement => {
  const { desc, title, variation, cta } = props;
  const customClass = `copy-block ${variation === 'column' ? 'copy-block--intro--column' : 'copy-block--intro'}`;

  return (
    <Wrapper>
      <section>
        <div className={customClass}>
          <div className='container container--large'>
            <div className={`${variation === 'column' ? 'copy-block copy-block--intro--column__content' : 'copy-block copy-block--intro'}`}>
              <h2>{title}</h2>
              <Text text={desc} />
              {cta &&
                <div>
                  <StrapiButton
                    data={cta}
                    customClassName={`${cta.className}`}
                  />
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export const IntroCta = (props: IntroProps): React.ReactElement => {
  const { ctaText, desc, title, variation } = props;
  const customClass = `copy-block ${variation === 'column' ? 'copy-block copy-block--intro--column' : 'copy-block copy-block--intro'}`;

  return (
    <section>
      <div className={customClass}>
        <h2>{title}</h2>
        <Text text={desc} />
        <a href="/">{ctaText}</a>
      </div>
    </section>
  );
};

export const IntroThreeColumn = (props: IntroProps): React.ReactElement => {
  const { header, title, desc, cta } = props;

  const threeColumnBgColor = props?.bgColor?.selectedColor;

  return (
    <Wrapper bgColor={threeColumnBgColor}>
      <div className='container container--large'>
        <div className='intro-three-column'>
          {header === "h1" ? <h1>{title}</h1> : <h2>{title}</h2>}
          <Text text={desc} />
          {cta &&
            <StrapiButton
              data={cta}
              customClassName={`button--${cta.className}`}
            />
          }
        </div>
      </div>
    </Wrapper>
  )
}

export const H2TextIntroOneColumn = (props: IntroProps): React.ReactElement => {
  const { title, desc, cta } = props;
  const introBgColor = props?.bgColor?.selectedColor;

  return (
    <Wrapper bgColor={introBgColor}>
      <div className='container container--large'>
        <div className='h2-intro-one-column'>
          <div className='h2-intro-one-column__content'>
            <h2>{title}</h2>
            <Text text={desc} />
          </div>
          {cta &&
            <div className='h2-intro-one-column__button'>
              <StrapiButton
                data={cta}
                customClassName={cta.className}
              />
            </div>
          }
        </div>
      </div>
    </Wrapper>
  );
}

export const ConnectWithExpert = (props: IntroProps): React.ReactElement => {
  const { desc, title, cta } = props;

  return (
    <section className="copy-block__connect-with-expert__container" >
      <div className="copy-block copy-block__connect-with-expert">
        <h1>{title}</h1>
        <div className="copy-block__connect-with-expert__block">
          <Text text={desc} />
          {cta && <StrapiButton customClassName={"button--connect-with-expert"} data={cta} />}
        </div>
      </div>
    </section>
  );
};

export const BrandBanner = (props: IntroProps): React.ReactElement => {
  const { desc } = props;

  return (
    <section className='copy-block__brand-banner-container'>
      <div className='copy-block__brand-banner'>
        <img className='copy-block__brand-banner__img' src={rvaLogo} alt='RVA Rising Logo' />
        {desc && <Text text={desc} />}
      </div>
    </section>
  );
}

export const PageDesc = (props: IntroProps): React.ReactElement => {
  const { ctaText, desc, title } = props;

  return (
    <section>
      <div className="copy-block copy-block--page-desc">
        <h1>{title}</h1>
        <Text text={desc} />
        <a href="/">{ctaText}</a>
      </div>
    </section>
  );
};

export const IntroIcon = (props: IntroProps): React.ReactElement => {
  const { ctaText, desc, title } = props;

  return (
    <section>
      <div className="copy-block copy-block--intro-icon">
        <FontAwesomeIcon icon={faFile} />
        <h1>{title}</h1>
        <Text text={desc} />
        <a href="/">{ctaText}</a>
      </div>
    </section>
  );
};

export const SingleColumn = (props: IntroProps): React.ReactElement => {
  const { desc, title, h1, p } = props;

  return (
    <section>
      <div className="copy-block copy-block__single-column">
        <h1 className="copy-block__single-column-title">{title || h1}</h1>
        <Text text={desc || p} />
      </div>
    </section>
  );
};

export const TextIntroTwoColumn = (props: IntroProps): React.ReactElement => {
  const { desc, title, h1, p } = props;

  return (
    <Wrapper>
      <section>
        <div className="copy-block copy-block__text-intro-two-column">
          <div className="copy-block copy-block__text-intro-two-column__grid">
            <div className="copy-block copy-block__text-intro-two-column__grid__top">
              <h1>{title || h1}</h1>
            </div>
            <Text text={desc || p} />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export const ResourceList = ({ title, description, listItems, bgColor }: ResourceListProps): React.ReactElement => {
  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <section>
        <div className='resource-list'>
          <IntroH1
            title={title}
            desc={description}
            variation='column'
          />
          <div className='resource-list__resource-container'>
            {listItems?.map(({ title, description, isAlternate, cta }, index) => {
              return (
                <div key={index} className={`resource-list__resource-wrapper ${isAlternate ? 'resource-list__resource--alt' : ''}`}>
                  <div className='resource-list__resource'>
                    <div className='resource-list__resource__title'>
                      <h4>{title}</h4>
                    </div>
                    <div className='resource-list__resource__paragraph'>
                      <Text text={description} />
                    </div>
                    <div className='resource-list__resource__button'>
                      {cta && (
                        <StrapiButton
                          data={cta}
                          customClassName={'button--resource-list'}
                          icon={cta.icon as IconName}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
