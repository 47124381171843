import Wrapper from 'components/Strapi/Wrapper';
import React from 'react';

import './stylesheets/color-bar.scss';

type ColorBarProps = {
  orientation: "left" | 'right',
  bgColor?: {
    selectedColor: string
  },
}

export const ColorBar = (props: ColorBarProps): React.ReactElement => {
  const { orientation, bgColor } = props;

  return (
    <Wrapper bgColor={bgColor?.selectedColor}>
      <div className={`color-bar ${orientation === 'left' ? 'color-bar--left' : 'color-bar--right'}`}>
        <div className='color-bar__top' />
        <div className='color-bar__mid' />
        <div className='color-bar__bottom' />
      </div>
    </Wrapper>
  )
}