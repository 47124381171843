import React from 'react';
import './stylesheets/image-blocks.scss';
import { Button } from 'components/Buttons';
import { IntroCta } from 'components/CopyBlocks/Intro';
import { Image as ImageProps } from 'shared/interfaces/Image';
import StrapiImage from 'components/Strapi/Image';
import { Color } from 'shared/interfaces/Color';
import Text from 'components/Text';

import StrapiButton from 'components/Strapi/Button';
import Wrapper from "components/Strapi/Wrapper";
import { Button as IButton } from 'shared/interfaces/Button';

type ImageBlockProps = {
  customClassName?: string;
  image?: string;
  img?: string;
  containerPosition?: string;
  title?: string;
  description?: string;
  headerClassName?: string | undefined;
  paragraphClassName?: string | undefined;
  buttonColor?: string | undefined;
  buttons?: {
    id: number;
    label: string;
    link: string;
    test?: void;
  }[],
};

type ImageBlockNewProps = {
  id?: number;
  customClassName?: string;
  h2?: string;
  h3?: string;
  p?: string;
  image?: ImageProps;
  optionalRte?: string;
  img?: string;
  containerPosition?: string;
  title?: string;
  description?: string;
  headerClassName?: string | undefined;
  paragraphClassName?: string | undefined;
  buttonColor?: string | undefined;
  cta?: IButton[];
  bgColor: Color;
  variation: string;
  reversed: boolean;
  fontColor?: 'brand' | 'white',
  paddingTop?: number;
  paddingBottom?: number;
};

type TwoColumnImageBlockProps = {
  image: string;
  containerPosition: string;
  title: string;
  description: string;
  headerClassName: string | undefined;
  paragraphClassName: string | undefined;
  buttonColor: string | undefined;
  buttons: {
    id: number;
    label: string;
    link: string;
  }[],
};

type ImageBlockThreePhotoCollageProps = {
  containerPosition: string
  h1: string;
  p: string;
  imageBlock: {
    customClassName: string;
    img: string;
    buttons: {
      id: number;
      label: string;
      link: string;
    }[],
  }[]
}

type KeyValueLookup = {
  [key: string]: string;
};

export const ImageBlockHover = (props: ImageBlockProps): React.ReactElement => {
  const { title, img } = props;

  return (
    <div className='image-block image-block--hover'>
      <div>
        <h4>{title}</h4>
        <img src={img} />
      </div>
    </div>
  )
}

export const ImageBlockNew = (props: ImageBlockNewProps): React.ReactElement => {
  const { id, title, description, image, cta, h2, h3, p, variation, bgColor, reversed, fontColor, optionalRte, paddingTop, paddingBottom } = props;

  const variationLookup: KeyValueLookup = {
    MODULE_50_50_TWOCOLUMN: "image-block--50-50",
    MODULE_30_70_TWOCOLUMN: "image-block--30-70",
    MODULE_40_60_TWOCOLUMN: "image-block--40-60",
  }

  return (
    <Wrapper paddingTop={paddingTop} paddingBottom={paddingBottom} bgColor={bgColor.selectedColor}>
      <div className='container container--large'>
        <div key={id} className={`image-block ${variationLookup[variation]} ${reversed ? "image-block--reversed" : ""}`}>
          {image &&
            <div className={`image-block ${variationLookup[variation]}__img`}>
              <StrapiImage data={image} />
            </div>
          }
          {optionalRte &&
            <div className={`image-block ${variationLookup[variation]}__rte`}>
              <Text text={optionalRte} trimmed={false} />
            </div>
          }
          <div className={`fontColor--${fontColor} ${reversed ? "image-block--reversed__text" : `image-block ${variationLookup[variation]}__text`}`}>
            <h2>{title || h2}</h2>
            <h3>{h3}</h3>
            <Text text={description || p} trimmed={false} />
            <div className={`image-block ${variationLookup[variation]}__actions`}>
              {cta && cta.map((button, index) => (
                <StrapiButton key={index} data={button} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export const ImageBlock = (props: ImageBlockProps): React.ReactElement => {
  const { title, img, customClassName, paragraphClassName, description, buttons, buttonColor } = props;

  return (
    <div className={`image-block ${customClassName ? customClassName : ''}`}>
      <div className='image-block__img'>
        <img src={img} />
      </div>
      <div className='image-block__text'>
        <h4>{title}</h4>
        <Text text={description} className={`${paragraphClassName}`} />
        <div className='image-block__actions'>
          {buttons && buttons.map(({ id, label, link }) => {
            return (
              <Button
                key={id}
                customClassName={`${buttonColor}`}
                text={label}
                link={link}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const TwoColumnImageBlock = (props: TwoColumnImageBlockProps): React.ReactElement => {
  const { image, title, description, containerPosition, headerClassName, paragraphClassName, buttonColor, buttons } = props;
  return (
    <div className={containerPosition} >
      <img className='image' src={image} />
      <div className="content">
        <h1 className={`title ${headerClassName}`}>{title}</h1>
        <Text text={description} className={`body ${paragraphClassName}`} />
        <div>
          {buttons && buttons.map(({ id, label, link }) => {
            return (
              <Button
                key={id}
                customClassName={`two-column-button ${buttonColor}`}
                text={label}
                link={link}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export const ImageBlockThreePhotoCollage = (props: ImageBlockThreePhotoCollageProps): React.ReactElement => {
  const { containerPosition, h1, p, imageBlock } = props;

  const contentPosition = `image-block ${containerPosition === 'left' ? 'image-block--three-photo-collage__content' : 'image-block--three-photo-collage__content--rtl'}`;
  const photoContainerPosition = `image-block ${containerPosition === 'left' ? 'image-block--three-photo-collage__container-position' : 'image-block--three-photo-collage__container-position--rtl'}`;

  return (
    <div className='image-block image-block--three-photo-collage'>
      <div className={contentPosition}>
        <div></div>
        <IntroCta
          title={h1}
          desc={p}
          ctaText='Learn More'
          variation='column'
        />
      </div>
      <div className={photoContainerPosition}>
        {imageBlock?.map(({ img, buttons, customClassName }, index) => {
          return (
            <div key={index} className={`${customClassName}`} style={{ backgroundImage: `url('${img}')` }}>
              {buttons && buttons.map(({ id, label, link }) => {
                return (
                  <div key={id} className='image-block image-block--three-photo-collage__button-position'>
                    <Button
                      text={label}
                      link={link}
                      customClassName='button--photo-collage'
                    />
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  );
}

export { ImageBlockNew as default };
